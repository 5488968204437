import apiServer from 'api/apiServer.js'

// 新增文章
export function articleAddApi(params = {}) {
  let url = `/article/add` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


//RSS新增文章
export function rssArticleAddApi(params = {}) {
    let url = `/rssArticle/add` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


// 新增文章
export function basicWordAddApi(params = {}) {
    let url = `/basic/add` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


// 新增文章
export function basicWordEditApi(params = {}) {
    let url = `/basic/edit` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}



export function queryShieldWordPageApi(params = {}) {
    let url = `/shield/word/queryShieldWordPage` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


export function queryValidWordPageApi(params = {}) {
    let url = `/valid/word/queryValidWordPage` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

// 删除屏蔽词
export function delShieldWordApi(params = {}) {
    let url = `/shield/word/delShieldWord/${params}` //post methods
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}

// 删除有效词
export function delValidWordApi(params = {}) {
    let url = `/valid/word/delValidWord/${params}` //post methods
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}


//新增屏蔽词
export function addShieldWordApi(params = {}) {
    let url = `/shield/word/addShieldWord` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//新增有效词
export function addValidWordApi(params = {}) {
    let url = `/valid/word/addValidWord` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function queryBasicWordApi(params = {}) {
    let url = `/basic/select` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}



export function basicWordSelectApi1(params = {}) {
    let url = `/basic/select2` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

// 新增文章
export function basicWordDelete(params = {}) {
    let url = `/basic/delete/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}

// 新增文章
export function articleSetTrueApi(params = {}) {
    let url = `/article/setTrue/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}




// 文章审核成功
export function articleSetFalseApi(params = {}) {
    let url = `/article/setFalse`
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}




export function basicPageApi(params = {}) {
    let url = `/basic/page` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//查询父类目下是否还有子类目
export function getChildrenByParentIdApi(params = {}) {
    let url = `/multistage/getChildrenByParentId/${params}` //post methods
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}



//编辑类目
export function updateByIdApi(params = {}) {
    let url = `/multistage/updateById` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


//删除类目
export function deleteWordByIdApi(params = {}) {
    let url = `/multistage/deleteWordById/${params}` //post methods
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}

// 多级分类Page分页 api
export function multistagePageApi(params = {}) {
    let url = `/multistage/page` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


//新增一级类目Api
export function addParentApi(params = {}) {
    let url = `/multistage/addParent` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//获取全部一级类目
export function getParentAllApi(params = {}) {
    let url = `/multistage/getParentAll` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//新增二级类目Api
export function addChildrenApi(params = {}) {
    let url = `/multistage/addChildren` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


export function basicStockPageApi(params = {}) {
    let url = `/basicStock/page` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function basicStockPageAllApi(params = {}) {
    let url = `/basicStock/pageAll` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


// 新增文章
export function basicStockAddApi(params = {}) {
    let url = `/basicStock/add` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}



//查询联系方式list'
export function getContactListApi(params = {}) {
    let url = `/basicStockContact/getList/${params}`
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}

// 新增文章
export function basicStockEditApi(params = {}) {
    let url = `/basicStock/edit` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}
// 新增文章
export function basicStockDeleteOne(params = {}) {
    let url = `/basicStock/deleteOne/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}

export function basicJobDeleteOne(params = {}) {
    let url = `/basicJob/deleteOne/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}
export function basicJobDeleteZero(params = {}) {
    let url = `/basicJob/deleteZero/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}

export function basicStockDeleteZero(params = {}) {
    let url = `/basicStock/deleteZero/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}

export function basicStockDeleteAll(params = {}) {
    let url = `/basicStock/deleteAll` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}

export function basicStockSelect(params = {}) {
    let url = `/basicStock/select` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}

export function basicStockSelectPlfp(params = {}) {
    let url = `/basicStock/selectPlfp` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}

export function basicStockSelectPljc(params = {}) {
    let url = `/basicStock/selectPljc` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}



export function basicStockStartAll(params = {}) {
    let url = `/basicStock/startAll` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}

export function basicStockCloseAll(params = {}) {
    let url = `/basicStock/closeAll` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}


    export function basicStocksendEmail(params = {}) {
        let url = `/basicStock/sendEmail` //post methods
        return apiServer.post(url,params).then(res => {
            return Promise.resolve(res)
        })
}

export function basicJobClientApi(params = {}) {
    let url = `/basicJob/userRole` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}


export function basicJobPage(params = {}) {
    let url = `/basicJob/page` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}
export function basicJobAdd(params = {}) {
    let url = `/basicJob/add` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}
export function basicJobEdit(params = {}) {
    let url = `/basicJob/edit` //post methods
    return apiServer.post(url,params).then(res => {
        return Promise.resolve(res)
    })
}

// 数据分析统计
export function numberConutApi(params = {}) {
    let url = `/article/queryPageNew` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}
// export function numberConutApi(params = {}) {
//     let url = `/article/numberCount` //post methods
//     return apiServer.post(url, params).then(res => {
//         return Promise.resolve(res)
//     })
// }


// 数据分析统计
export function jsConutApi(params = {}) {
    let url = `/article/jsCount` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

// 数据分析统计
export function moneyConutApi(params = {}) {
    let url = `/article/moneyCount` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

// 数据分析统计
export function amediaManagePageApi(params = {}) {
    let url = `/mediaManage/page` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function amediaManageDelApi(params = {}) {
    let url = `/mediaManage/delete/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}

export function amediaManageAddApi(params = {}) {
    let url = `/mediaManage/add` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function amediaManageGetApi(params = {}) {
    let url = `/mediaManage/get/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}

export function acontractManageDelApi(params = {}) {
    let url = `/contractManage/delete/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}
export function acontractManageGetApi(params = {}) {
    let url = `/contractManage/get/${params}` //post methods
    return apiServer.put(url).then(res => {
        return Promise.resolve(res)
    })
}
export function acontractManageAddApi(params = {}) {
    let url = `/contractManage/add` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}
// 数据分析统计
export function acontractManagePageApi(params = {}) {
    let url = `/contractManage/page` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}




// 数据分析统计
export function treeContentypeApi() {
    let url = `/article/treeContentype` //post methods
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}

// 数据分析统计
export function timeTimerApi() {
    let url = `/article/timeTimer` //post methods
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}



// 获取启用的客户
export function getClientApi(params = {}) {
    let url = `/basicStock/getClient` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}




// 获取名称列表
export function selectApi(params = {}) {
    let url = `/article/selectList` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//根据公司名称获取id
export function getStockIdByStockNameApi(params = {}) {
    let url = `/basicStock/getStockIdByStockName/${params}`
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}

// 文章列表
export function articlePageApi(params = {}) {
    let url = `/article/page` //post methods
    // let url = `/article/miniPage` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//RSS文章列表
export function articleRssPageApi(params = {}) {
    let url = `/rssArticle/page` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//无效列表导出
export function invalidExportApi(params = {}) {
    return apiServer
      .downfileStream(`/article/invalidExport`,params)
      .then((res) => {
          return Promise.resolve(res);
      });
}


export function authStatePassApi(params = {}) {
    let url = `/article/authStatePass` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}





export function articlePageApiSH(params = {}) {
    let url = `/article/pageSH` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

// 修改文章
export function articleEditApi(params = {}) {
    let url = `/article/edit` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//文章状态统计
export function statusStatisticsApi(params = {}) {
  let url = `/article/statusStatistics` //post methods
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 文章审核成功
export function authStateSuccessApi(params = {}) {
  let url = `/article/authStateSuccess/${params}`
  return apiServer.put(url).then(res => {
    return Promise.resolve(res)
  })
}

// 文章审核失败
export function authStateFailApi(params = {}) {
  let url = `/article/authStateFail`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}











export function authStateSuccessApiYX(params = {}) {
    let url = `/article/authStateSuccess`
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}



// 文章详情
export function articlecontentApi(params = {}) {
  let url = `/article/content/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

//验证文章是否被冻结
// export function checkBrieflyStateApi(params = {}) {
//     let url = `/article/checkBrieflyState/${params}`
//     return apiServer.get(url).then(res => {
//         return Promise.resolve(res)
//     })
// }

//RSS文章详情
export function articleRssContentApi(params = {}) {
    let url = `/rssArticle/content/${params}`
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}

// 文章详情
export function authStockName(params = {}) {
    let url = `/article/authStockName/${params}`
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}


// 修改文章状态-修改为屏蔽
export function frozenApi(params = {}) {
  let url = `/article/updFreezeState/frozen`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}
// 修改文章状态-修改为显示
export function normalApi(params = {}) {
  let url = `/article/updFreezeState/normal`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function articleByFailReasonExportApi(params = {}) {
    return apiServer
      .downfileStream("/article/exportByFailReason", params)
      .then((res) => {
          return Promise.resolve(res);
      });
}

// 文章列表导出
export function articleExportApi(params = {}) {
  return apiServer
    .downfileStream("/article/export", params)
    .then((res) => {
      return Promise.resolve(res);
    });
}


//数据分析导出-New
export function articleDataCountExportApi(params = {}) {
    return apiServer
      .downfileStream("/article/dataCountExport", params)
      .then((res) => {
          return Promise.resolve(res);
      });
}


//获取行业名称
export function getIndustryNameByCodeApi(params = {}) {
    let url = `/article/getIndustryNameByCode/${params}`
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}


//数据分析导出
export function articleExportCountApi(params = {}) {
    return apiServer
        .downfileStream("/article/exportCount", params)
        .then((res) => {
            return Promise.resolve(res);
        });
}

//数据分析导出-New
export function articleExportCountNewApi(params = {}) {
    return apiServer
        .downfileStream("/article/exportCountNew", params)
        .then((res) => {
            return Promise.resolve(res);
        });
}

//数据分析导出
export function mediaExportCountApi(params = {}) {
    return apiServer
        .downfileStream("/mediaManage/exportCount", params)
        .then((res) => {
            return Promise.resolve(res);
        });
}

//数据分析导出
export function contractManageExportCountApi(params = {}) {
    return apiServer
        .downfileStream("/contractManage/exportCount", params)
        .then((res) => {
            return Promise.resolve(res);
        });
}



export function articExportOperationAssessmentApi(params = {}) {
    return apiServer
      .downfileStream("/article/exportOperationAssessment", params)
      .then((res) => {
          return Promise.resolve(res);
      });
}
//数据分析导出
export function articleExportMoneyCountApi(params = {}) {
    return apiServer
        .downfileStream("/article/exportMoneyCount", params)
        .then((res) => {
            return Promise.resolve(res);
        });
}


//审核条数导出
export function articleExportAuditApi(params = {}) {
    return apiServer
      .downfileStream("/article/exportAuditRecord", params)
      .then((res) => {
          return Promise.resolve(res);
      });
}

//数据分析导出
export function articleExportJsCountApi(params = {}) {
    return apiServer
        .downfileStream("/article/exportJsCount", params)
        .then((res) => {
            return Promise.resolve(res);
        });
}

//导出报告New


//文本数据分析导出
export function articleExportSelectListApi(params = {}) {
    return apiServer
        .downfileStream("/article/exportSelectList", params)
        .then((res) => {
            return Promise.resolve(res);
        });
}
// 获取所有的文章内容类型
export function contentTypeAllApi() {
  let url = `/article/contentTypeAll`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}


export function getRelevanceInfoApi(params = {}) {
    let url = `/basicStock/getRelevanceInfo/${params}`
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}

//获取所有邮件关联方式
export function getAssociatedEmailAllApi(params = {}) {
    let url = `/basicStock/getAssociatedEmailAll/${params}`
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}

//动态改变关联联系人邮箱手机号
export function loadingEmailPhoneApi(params = {}) {
    let url = `/basicStock/loadingEmailPhone` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}



// 开通客户
export function openClientApi(params = {}) {
    let url = `/basicStock/openClient` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}



//批量更新
export function relevantAllApi(params = {}) {
    let url = `/basicStock/relevantAll` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//批量删除
export function delBatchesApi(params = {}) {
    let url = `/basicStock/delBatches` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//
export function freezeClientApi(params = {}) {
    let url = `/basicStock/freezeClient/${params}` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//查询开通客户手机号
export function queryOpenClientPhoneApi(params = {}) {
    let url = `/basicStock/queryOpenClientPhone/${params}` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}





//查询是否已开通客户
export function queryIsOpenClientApi(params = {}) {
        let url = `/basicStock/queryIsOpenClient/${params}` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}


//加载客户所有手机号
export function loadClientPhoneAllApi(params = {}) {
    let url = `/basicStock/loadClientPhoneAll/${params}` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}


//获取所有证监会
export function getSecBureauAllApi(params = {}) {
        let url = `/basicStock/getSecBureauAll` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//按照辖区分配
export function secBureauAllocationApi(params = {}) {
    let url = `/basicStock/secBureauAllocation` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//导出全部公司信息
export function basicStockAllExportApi(params = {}) {
    return apiServer
      .downfileStream("/basicStock/basicStockAllExport", params)
      .then((res) => {
          return Promise.resolve(res);
      });
}

//删除客户联系方式
export function deleteContactApi(params = {}) {
    let url = `/basicStockContact/deleteContact/${params}`
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}


export function upIsDelContactApi(params = {}) {
    let url = `/basicStockContact/upIsDelContact` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//导出客户联系方式
export function basicStockContactExportApi(params = {}) {
    return apiServer
      .downfileStream("/basicStockContact/exportContact", params)
      .then((res) => {
          return Promise.resolve(res);
      });
}


//重置客户时间
export function resetApi(params = {}) {
    let url = `/basicStock/reset/${params}`
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}

export function trialSetApi(params = {}) {
    let url = `/basicStock/trialSet` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

//查询是否存在试用时间
export function checkTrialInfoByStockIdApi(params = {}) {
    let url = `/basicStock/checkTrialInfoByStockId/${params}`
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}


export function getTrialInfoByStockIdApi(params = {}) {
    let url = `/basicStock/getTrialInfoByStockId/${params}`
    return apiServer.post(url).then(res => {
        return Promise.resolve(res)
    })
}

//数据展示初始化API
export function RenderingChartsAPi(params = {}) {
    let url = `/dataDisplay/renderingCharts` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getSecBureauRaningInfoApi(params = {}) {
    let url = `/dataDisplay/getSecBureauRaningInfo` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getIndustryRaningInfoApi(params = {}) {
    let url = `/dataDisplay/getIndustryRaningInfo` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getIndustryRankingAllInfoApi(params = {}) {
    let url = `/dataDisplay/getIndustryRankingAllInfo` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getSecBureauRankingAllInfoApi(params = {}) {
    let url = `/dataDisplay/getSecBureauRankingAllInfo` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getStockRankingAllInfoApi(params = {}) {
    let url = `/dataDisplay/getStockRankingAllInfo` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}
export function getRelevanceUserApi(params = {}) {
    let url = `/client/getRelevanceUser` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function queryRelevanceSecBureauApi(params = {}) {
    let url = `/client/queryRelevanceSecBureau` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function queryRelevanceIndustryApi(params = {}) {
    let url = `/client/queryRelevanceIndustry` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getAllDistributeNumApi(params = {}) {
    let url = `/article/getAllDistributeNum` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getPushApiNumApi(params = {}) {
    let url = `/article/getPushApiNum` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function createdCompanyWordApi(params = {}) {
    return apiServer
      .downfileStream(`/created/createdCompanyWord`,params)
      .then((res) => {
          return Promise.resolve(res);
      });
}


export function automationCreatedCompanyWordApi(params = {}) {
    let url = `/created/automationCreatedCompanyWord` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function automationCreatedEsgWordApi(params = {}) {
    let url = `/created/automationCreatedEsgWord` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


export function automationCreatedInteractWordApi(params = {}) {
    let url = `/created/automationCreatedInteractWord` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


export function createdInteractWordApi(params = {}) {
    return apiServer
      .downfileStream(`/created/createdInteractWord`,params)
      .then((res) => {
          return Promise.resolve(res);
      });
}

export function createdEsgWordApi(params = {}) {
    return apiServer
      .downfileStream(`/created/createdEsgWord`,params)
      .then((res) => {
          return Promise.resolve(res);
      });
}

export function createdPressWordApi(params = {}) {
    return apiServer
      .downfileStream(`/created/createdPressWord`,params)
      .then((res) => {
          return Promise.resolve(res);
      });
}

export function getStockBySignAndSigningApi(params = {}) {
    let url = `/basicStock/getStockBySignAndSigning` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}
export function getRelevanceStockListApi(params = {}) {
    let url = `/basicStock/getRelevanceStockList` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}



export function openClientAccountApi(params = {}) {
    let url = `/basicStock/openClientAccount` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getMediaBoxAmountApi(params = {}) {
    let url = `/media/box/getMediaBoxAmount` //post methods
    return apiServer.get(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getStockAmountPageApi(params = {}) {
    let url = `/basicStock/getStockAmountPage` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function upStockStateApi(params = {}) {
    let url = `/basicStock/upStockState` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}
export function getStockAmountDetailApi(params = {}) {
    let url = `/media/box/getStockAmountDetail` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function editStockAmountApi(params = {}) {
    let url = `/basicStock/editStockAmount` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


export function getStockActiveRankPageByTimeApi(params = {}) {
    let url = `/article/getStockActiveRankPageByTime` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getIndustryActiveRankPageByTimeApi(params = {}) {
    let url = `/article/getIndustryActiveRankPageByTime` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function loadArticleReviewPageApi(params = {}) {
    let url = `/article/review/loadArticleReviewPage` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function reviewPassApi(params = {}) {
    let url = `/article/review/pass/${params}` //post methods
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}


export function reviewNoPassApi(params = {}) {
    let url = `/article/review/noPass` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


export function reviewBatchNoPassApi(params = {}) {
    let url = `/article/review/batchNoPass` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}



export function reviewBatchPassApi(params = {}) {
    let url = `/article/review/batchPass` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function loadEsgWordPageApi(params = {}) {
    let url = `/esg/loadEsgWordPage` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function addEsgWordApi(params = {}) {
    let url = `/esg/addWord/${params}` //post methods
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}

export function deleteEsgWordApi(params = {}) {
    let url = `/esg/deleteWord/${params}` //post methods
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}


export function getUntreatedListApi(params = {}) {
    let url = `/printScreen/untreatedList/${params}` //post methods
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}

