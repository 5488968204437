import apiServer from 'api/apiServer.js'

// 公司管理里  列表查询
export function companyPageApi(params = {}) {
  let url = `/company/page` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 企业和企业用户新增
export function companyAndUserAddApi(params = {}) {
  let url = `/company/companyAndUserAdd` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
// 企业和企业用户新增
export function companyDetailsEditApi(params = {}) {
  let url = `/company/companyDetailsEdit` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 企业详情
export function companyDetailsApi(params = {}) {
  let url = `/company/companyDetails/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}
// 修改公司状态-修改为正常
export function companyupdFreezeStateApi(params = {}) {
  let url = `/company/updFreezeState/normal`
  return apiServer.put(url,params).then(res => {
    return Promise.resolve(res)
  })
}

// 修改公司状态-修改为冻结
export function companyfrozenApi(params = {}) {
  let url = `/company/updFreezeState/frozen`
  return apiServer.put(url,params).then(res => {
    return Promise.resolve(res)
  })
}

// 公司列表-运营后台(导出)
export function platformExportApi(params = {}) {
  return apiServer
    .downfileStream("/company/export", params)
    .then((res) => {
      return Promise.resolve(res);
    });
}

// 二维码登录
export function orCodeRegisterApi(params = {}) {
  let url = `/client/orCodeRegister`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

//客户信息编辑保存方法
export function saveInfoApi(params = {}) {
  let url = `/client/saveInfo`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


//信息审核页面初始化方法
export function getClientInfoAllApi(params = {}) {
  let url = `/client/page`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getClientInfoByIdApi(params = {}) {
  let url = `/client/getInfoById/${params}` //post methods
  return apiServer.post(url).then(res => {
    return Promise.resolve(res)
  })
}


export function auditOKApi(params = {}) {
  let url = `/client/audit/${params}` //post methods
  return apiServer.post(url).then(res => {
    return Promise.resolve(res)
  })
}


//比对
export function comparisonApi(params = {}) {
  let url = `/client/comparison/${params}` //post methods
  return apiServer.post(url).then(res => {
    return Promise.resolve(res)
  })
}


//验证股票代码
export function checkStockCodeApi(params = {}) {
  let url = `/client/checkStockCode/${params}`
  return apiServer.post(url).then(res => {
    return Promise.resolve(res)
  })
}

export function getPublicCompanyPageApi(params = {}) {
  let url = `/public/company/getPublicCompanyPage` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function addPublicCompanyApi(params = {}) {
  let url = `/public/company/addPublicCompany` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getUnifyAffiliatedCompanyListApi(params = {}) {
  let url = `/public/company/getUnifyAffiliatedCompanyList/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}


export function queryStockByNameApi(params = {}) {
  let url = `/basicStock/queryStockByName/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function associatedCompanyApi(params = {}) {
  let url = `/public/company/associatedCompany` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function unassociatedCompanyApi(params = {}) {
  let url = `/public/company/unassociatedCompany` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function selectPublicCompanyInfoApi(params = {}) {
  let url = `/public/company/selectPublicCompanyInfo/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}


export function editPublicCompanyInfoApi(params = {}) {
  let url = `/public/company/editPublicCompanyInfo` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}